import React from 'react'

const PageNot = () => {
  return (
    <div className='form flex-column '>
      <h1>404</h1>
      <h1>Page Not Found</h1>
    </div>
  )
}

export default PageNot